import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const ContactUs = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Reservation Form"
				description="This form is required to be completed in full and submitted within 7 days of your deposit."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/upcoming-rides",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// ]}
			/>
			<section className="my-20">
				<h1 className="font-bold">Reservation Form</h1>
				<p className="font-light mt-2 text-xl text-primary max-w-3xl mb-14">
					This form is required to be completed in full and submitted within 7 days
					of your deposit
				</p>
				{/* <Helmet>
					<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
				</Helmet> */}
				<iframe
					class="airtable-embed"
					src="https://airtable.com/embed/appRR200KwfeK6LtL/shrOdZmV5bSw9jAgo"
					frameborder="0"
					onmousewheel=""
					width="100%"
					height="533"
					//style="background: transparent; border: 1px solid #ccc;"
				></iframe>
				{/* <iframe
					class="airtable-embed airtable-dynamic-height"
					src="https://airtable.com/embed/shrsIJ2lrqXx5WNIz?backgroundColor=green"
					frameborder="0"
					onmousewheel=""
					width="100%"
					height="6250"
				></iframe> */}
			</section>
		</Layout>
	);
};

export default ContactUs;
